<template lang="html">
  <div class="collapsable-info" :class="{'expanded': show}">
    <div class="open-collapsable-info" v-if="!show" @click="$emit('open')"><span>?</span> How it works</div>
    <div class="collapsable-info-wrapper" v-if="show">
      <div class="close-collapsable-info" @click="$emit('close')"><i class="fa fa-times"></i></div>
      <div class="collapsable-info-wrapper-content">
        <div class="collapsable-info-title">A quick word on how Studio works</div>
        <p>You can create as many ads as you'd like at absolutely no cost, so we encourage you to play around and experiment.</p>
        <p>Every ad you create comes with 1.000 free impressions, so you can feel safe testing your creations before going live.</p>
        <p>After you've created an ad you will be emailed a link to a dashboard where you can keep track of how many impressions, clicks and other statistics it has amassed this and previous months.<p>
        <p>Around the start of every month we'll send you an invoice for the impressions your ads have generated the previous period. That means if you run a fullscreen image ad 100.000 times per month at 5CPM, we'll bill you 495 NOK for the first month and 500 NOK for consecutive months for as long as you want to run the ad.</p>
        <p>Sound easy enough, right? So just go ahead and try it out and feel free to contact us at <a href="mailto:studio@madington.com" target="_blank">Madington</a> if you've got any thoughts or questions.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show']
}
</script>

<style lang="scss" scoped>
  .collapsable-info {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: #c9e4fb;
    z-index: 5;
    &-wrapper {
      background: #2196f3;
      width: 450px;
      max-width: 80vw;
      border-radius: 10px;
      box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07);
      &-content {
        padding: 1.5em;
        position: relative;
      }
    }
    &-title {
      font-size: 0.9em;
      color: #edf2f6;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
      margin-bottom: 0.5em;
      z-index: 1;
    }
    p {
      font-size: 0.7em;
      color: #c9e4fb;
      line-height: 1.2;
      margin: 0;
      & + p {
        margin-top: 0.5em;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
        font-weight: 500;
      }
    }
  }
  .close-collapsable-info {
    color: #c9e4fb;
    position: absolute;
    top: .5em;
    right: .5em;
    font-size: 0.8em;
    z-index: 2;
    height: 1.5em;
    width: 1.5em;
    transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
    cursor: pointer;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      transform-origin: top left;
      vertical-align: middle;
      line-height: 1;
    }
    &:hover {
      color: #fff;
    }
  }
  .open-collapsable-info {
    background: #2196f3;
    color: #fff;
    border-radius: 3em;
    padding: 0.55em 1em 0.55em;
    font-size: 0.7em;
    cursor: pointer;
    transition: 0.7s color cubic-bezier(0.25,1,0.25,1), 0.7s background cubic-bezier(0.25,1,0.25,1);
    line-height: 1;
    vertical-align: middle;
    span {
      font-size: 1.2em;
      color: #91cbf9;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
      margin-right: 0.3em;
      line-height: 1;
      vertical-align: middle;
    }
    &:hover {
      color: #fff;
      background: rgb(28, 123, 199);
    }
  }
</style>
