<template lang="html">
  <div class="discount-info" :class="{'expanded': show}" v-if="data && data.data.active">
    <div class="open-discount-info" v-if="!show" @click="toggle()" v-html="data.data.button_name[0].text"></div>
    <div class="discount-info-wrapper" v-if="show">
      <div class="close-discount-info" @click="toggle()"><i class="fa fa-times"></i></div>
      <div class="discount-info-wrapper-content">
        <div class="discount-info-title" v-html="data.data.content_title[0].text"></div>
        <p v-for="text in data.data.content_text" v-html="text.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      data: false,
      // show: false,
    };
  },
  async mounted() {
    const prismicAppData = await this.$prismic.api.getSingle("discount_button");
    this.$set(this, "data", prismicAppData);
  },
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
};
</script>

<style lang="scss">
.discount-info {
  p {
    a {
      color: #fff;
      text-decoration: none;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss" scoped>
.discount-info {
  position: fixed;
  bottom: 1rem;
  right: calc(2rem + 137px);
  color: #fff;
  z-index: 5;
  &-wrapper {
    background: #2fd085;
    width: 450px;
    max-width: 80vw;
    border-radius: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
    &-content {
      padding: 1.5em;
      position: relative;
    }
  }
  &-title {
    font-size: 0.9em;
    color: #fff;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
    font-weight: 500;
    margin-bottom: 0.5em;
    z-index: 1;
  }
  p {
    font-size: 0.7em;
    color: #fff;
    line-height: 1.2;
    margin: 0;
    & + p {
      margin-top: 0.5em;
      a > * {
        color: #fff;
        text-decoration: none;
        font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
        font-weight: 500;
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
    }
  }
}
*a {
  color: #fff;
  text-decoration: none;
  font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
  font-weight: 500;
}
.close-discount-info {
  color: #fff;
  opacity: 0.5;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 0.8em;
  z-index: 2;
  height: 1.5em;
  width: 1.5em;
  transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
  cursor: pointer;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    transform-origin: top left;
    vertical-align: middle;
    line-height: 1;
  }
  &:hover {
    // color: #fff;
    opacity: 1;
  }
}
.open-discount-info {
  background: #2fd085;
  color: #fff;
  border-radius: 3em;
  padding: 0.55em 1em 0.55em;
  padding: 0.75em 2em 0.55em;
  font-size: 0.7em;
  cursor: pointer;
  transition: 0.7s color cubic-bezier(0.25, 1, 0.25, 1),
    0.7s background cubic-bezier(0.25, 1, 0.25, 1);
  line-height: 1;
  vertical-align: middle;
  span {
    font-size: 1.2em;
    color: #91cbf9;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
    font-weight: 500;
    margin-right: 0.3em;
    line-height: 1;
    vertical-align: middle;
  }
  &:hover {
    color: #fff;
    background: #249e65;
  }
}
</style>
